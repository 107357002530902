import { fetch, fetchJson } from "js/includes/common/utils"

const VULN_PATCHING_BASE_URL = "/vulnerability-mgmt/vendor/ninjaone-patching"

export const fetchAppConfiguration = app => fetchJson(`/divisionconfig/${app}`)

export const toggleTeamViewerStatus = ({ mode, enabled }) =>
  fetchJson("/divisionconfig/teamviewer", {
    options: {
      method: "PUT",
      body: JSON.stringify({
        content: {
          mode,
          divisionEnabled: !enabled,
        },
      }),
    },
  })

export const toggleSplashtopStatus = enabled =>
  fetchJson("/divisionconfig/splashtop", {
    options: {
      method: "PUT",
      body: JSON.stringify({
        content: {
          enabled: enabled,
        },
      }),
    },
  })

export const toggleWingetStatus = ({ enabled }) =>
  fetchJson("/divisionconfig/winget", {
    options: {
      method: "PUT",
      body: JSON.stringify({
        content: {
          enabled: !enabled,
        },
      }),
    },
  })

export const fetchSentinelOneRedirectLink = () => fetchJson("/integrations/sentinelone/sso-redirect-link")

export const enableSentinelOneIntegration = ({
  username,
  password,
  apiToken,
  ninjaManaged = true,
  managementConsoleDomain,
  siteId,
}) => {
  return fetchJson(`/integrations/sentinelone/users/${apiToken ? "api-token" : "login"}`, {
    options: {
      method: "POST",
      body: JSON.stringify({
        username,
        password,
        apiToken,
        ninjaManaged,
        managementConsoleDomain,
        siteId,
      }),
    },
  })
}

export const enableViaMFA = ({ managementConsoleDomain, username, mfaToken, mfaCode, ninjaManaged = true, siteId }) =>
  fetchJson("/integrations/sentinelone/users/login/mfa", {
    options: {
      method: "POST",
      body: JSON.stringify({
        managementConsoleDomain,
        username,
        token: mfaToken,
        code: mfaCode,
        ninjaManaged,
        siteId,
      }),
    },
  })

export const disableSentinelOneIntegration = () =>
  fetchJson("/divisionconfig/sentinel_one", {
    options: {
      method: "PATCH",
      body: JSON.stringify({
        content: {
          enabled: false,
        },
      }),
    },
  })

export const saveSentinelOneMappings = mappings =>
  fetchJson("/integrations/sentinelone/sites", {
    options: {
      method: "PUT",
      body: JSON.stringify(mappings),
    },
  })

export const fetchSentinelOnePollerStatus = () => fetchJson("/integrations/sentinelone/accounts/details")

export const resetSentinelOnePoller = () =>
  fetchJson("/integrations/sentinelone/accounts/reset/apipoller", {
    options: {
      method: "POST",
    },
  })

export const saveITGlueConfiguration = ({ hostname, enabled }) =>
  fetchJson("/divisionconfig/itglue", {
    options: {
      method: "PUT",
      body: JSON.stringify({
        content: {
          hostname,
          enabled,
        },
      }),
    },
  })

export const deleteIntegration = app =>
  fetchJson(`/divisionconfig/${app}`, {
    options: {
      method: "DELETE",
    },
  })

export const addIntegration = (app, content) =>
  fetchJson(`/divisionconfig/${app}`, {
    options: {
      method: "PUT",
      body: JSON.stringify({
        content,
      }),
    },
  })

export const fetchIntegrations = () => fetchJson("/divisionconfig/all")

export const synchronizeGravityZoneThreats = deviceId =>
  fetchJson(`/integrations/gravityzone/nodes/${deviceId}/threat-sync`, {
    options: {
      method: "POST",
    },
  })

export const getGravityZonePartnerDetails = companyId =>
  fetchJson(`/integrations/gravityzone/partners/${companyId}`, {
    options: {
      method: "GET",
    },
  })

export const getGravityZoneDivisionDetails = () =>
  fetchJson(`/integrations/gravityzone/division`, {
    options: {
      method: "GET",
    },
  })

export const integrateGravityZoneAccount = companyId =>
  fetch("/integrations/gravityzone/partners/migration", {
    options: {
      method: "POST",
      body: JSON.stringify({ gravityZoneRootCompanyId: companyId }),
    },
  })

export const getGravityZoneDetailsByNode = nodeId => fetchJson(`/integrations/gravityzone/nodes/${nodeId}`)

export const getGravityZoneDetailsByOrg = clientId => fetchJson(`/integrations/gravityzone/organizations/${clientId}`)
export const getGravityZoneModulesByOrg = clientId =>
  fetchJson(`/integrations/gravityzone/organizations/${clientId}/modules`)

export const updateGravityZoneModulesByOrg = ({ clientId, modules }) => {
  const body = {
    addons: {
      exchangeSecurity: modules.exchangeSecurity,
      emailSecurity: modules.emailSecurity,
      diskEncryption: modules.diskEncryption,
      virtualizeEnvironments: modules.virtualizeEnvironments,
      containerProtection: modules.containerProtection,
      ats: {
        hyperDetect: modules.hyperDetect,
        sandboxAnalyzer: modules.sandboxAnalyzer,
      },
      edr: modules.edr,
      integrityMonitoring: {
        enabled: modules.integrityMonitoring,
        dataRetention: modules.integrityMonitoring ? Number(modules.dataRetention) : null,
      },
    },
  }
  return fetchJson(`/integrations/gravityzone/organizations/${clientId}/modules`, {
    options: {
      method: "POST",
      body: JSON.stringify(body),
    },
  })
}

export const enableCrowdStrikeIntegration = requestParams => {
  return fetchJson("/integrations/crowdstrike/auth/authenticate", {
    options: {
      method: "POST",
      body: JSON.stringify(requestParams),
    },
  })
}

export const disableCrowdStrikeIntegration = () =>
  fetchJson("/divisionconfig/crowdstrike", {
    options: {
      method: "PATCH",
      body: JSON.stringify({
        content: {
          enabled: false,
        },
      }),
    },
  })

export const toggleVulnerabilityMgmt = (isEnabled, vendor) =>
  fetchJson(
    vendor
      ? `/divisionconfig/vulnerability_management_${vendor.toLowerCase()}`
      : "/divisionconfig/vulnerability_management",
    {
      options: {
        method: "PUT",
        body: JSON.stringify({
          content: {
            enabled: !isEnabled,
          },
        }),
      },
    },
  )

export const togglePatchingVulnsHealth = () => {
  return fetchJson(`${VULN_PATCHING_BASE_URL}/toggle-affects_device_health`, {
    options: {
      method: "PATCH",
    },
  })
}

export const fetchPatchingVulnsStatus = () => fetchJson(`${VULN_PATCHING_BASE_URL}/affects_device_health`)

export const fetchQuickConnectStatus = () => fetchJson("/divisionconfig/quickconnect")

export const toggleQuickConnectStatus = enabled =>
  fetchJson("/divisionconfig/quickconnect", {
    options: {
      method: "PUT",
      body: JSON.stringify({
        content: {
          enabled,
        },
      }),
    },
  })

export const getWorkrampLoginUrl = () => fetchJson("/ws/integrations/workramp/login", { useSessionPrefix: false })

export const getNinjaItamDivisionConfig = () => {
  return fetchJson(`/divisionconfig/ninja_itam`)
}

export const saveNinjaItamDivisionConfig = config => {
  return fetchJson(`/divisionconfig/ninja_itam`, {
    options: {
      method: "PUT",
      body: JSON.stringify(config),
    },
  })
}
