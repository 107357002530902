import { NinjaResponseError } from "js/includes/common/types"
import { fetchJson } from "js/includes/common/utils"

export const getUnmanagedDevice = async nodeId => {
  const [nodeResponse] = await Promise.all([fetchJson(`/node/${nodeId}`), window.deviceList.fetchById(nodeId)])

  if (nodeResponse.resultCode === "SUCCESS") {
    const { node, healthStatus } = nodeResponse

    return {
      ...node,
      healthStatus,
    }
  } else {
    throw new NinjaResponseError(nodeResponse)
  }
}

export const createUnmanagedDevice = async unmanagedDevice => {
  return fetchJson("/unmanaged-device", {
    options: {
      method: "POST",
      body: JSON.stringify(unmanagedDevice),
    },
  })
}

export const updateUnmanagedDevice = async (nodeId, unmanagedDevice) => {
  return fetchJson(`/unmanaged-device/${nodeId}`, {
    options: {
      method: "PATCH",
      body: JSON.stringify(unmanagedDevice),
    },
  })
}

export const deleteUnmanagedDevice = async nodeId => {
  return fetchJson(`/unmanaged-device/${nodeId}`, {
    options: {
      method: "DELETE",
    },
  })
}
