import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  compose,
  find,
  propEq,
  curry,
  mergeLeft,
  path,
  pathOr,
  prop,
  forEach,
  any,
  values,
  includes,
  assocPath,
  omit,
  isNil,
} from "ramda"

import { sizer, useMountedState } from "@ninjaone/utils"
import { TitleGroup, Select, Switch, DataTable, AlertMessage, Input, Body } from "@ninjaone/components"
import { spacing } from "@ninjaone/tokens"

import {
  localizationKey,
  localized,
  isNotNil,
  reportErrorAndShowMessage,
  isAndroidMDMAppEnabled,
  showSuccessMessage,
} from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"
import { updatePolicyItem, deletePolicyItem, revertPolicySection } from "js/state/actions/policyEditor/editor"
import {
  fetchEnterpriseInformation as fetchAndroidConnections,
  getApplicationByPackageName,
} from "js/includes/common/client"
import showModal from "js/includes/common/services/showModal"
import { canCreateMDMConnection, canViewMDMConnection } from "js/includes/configuration/integrations/mdm/permissions"
import { MDMPermissionType } from "js/includes/configuration/integrations/mdm/constants"
import MobileApplicationsSelectorModal from "js/includes/editors/Policy/PolicyEditor/formComponents/MobileApplicationsSelectorModal"
import MobileApplicationsPolicyModal, {
  pickAppPolicyFields,
} from "js/includes/editors/Policy/PolicyEditor/formComponents/MobileApplicationPolicyModal"
import {
  AndroidPolicyAppsApplicationSources as ApplicationSources,
  AndroidPolicyAppsInstallTypeOptions as InstallTypeOptions,
  checkKioskAppExist,
  defaultInheritance,
  getActiveStatusColumn,
  getStatusActions,
  getOverrideStateColumn,
  isInheritedRow,
  isNotOverriddenRow,
  formatApplicationId,
} from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"
import {
  addAndroidConnectionsAlertMessage,
  alertMessageTokens,
  cleanSettingsData,
  contentProtectionPolicyOptions,
  getApplicationTypeOptions,
  getPlayStoreAppType,
  mapApplicationsId,
  PermissionPolicy,
  permissionPolicyOptions,
  PlayStoreAppTypes,
  playStoreModeOptions,
  untrustedAppsPolicyOptions,
} from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/android/applications/utils"
import SystemAppModal from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/android/SystemAppModal"
import DeleteAppModal from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/dialogs/DeleteAppModal"
import {
  getApplicationMetadata,
  isInputEmptyOrHasValidPackageName,
} from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/android/util"
import InheritableRowPolicyItem from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/common/InheritableRowPolicyItem"
import {
  FlexRowWrapper,
  LabelWrapper,
  SelectWrapper,
  StyledTitleLabel,
} from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/common/styled"
import { FieldRow, GroupHeading } from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/SecurityForm"

const LOCK_ICON = "LockIconSvg"

const canViewConnection = entityId => canViewMDMConnection(MDMPermissionType.Android, { entityId })
const getLockIcon = shouldHideApp => (shouldHideApp ? LOCK_ICON : null)
const getAccessorValue = ({ shouldHideApp, value }) => {
  if (shouldHideApp) {
    return localized("Insufficient permissions")
  }
  return value
}
const columns = isChildPolicy => [
  {
    id: "icon",
    Header: localized("Icon"),
    getImg: ({ iconUrl, applicationSource, shouldHideApp }) => {
      const isSystemApp = applicationSource === ApplicationSources.SYSTEM_APP
      const showSystemAppIcon = isSystemApp || shouldHideApp
      return iconUrl || showSystemAppIcon
        ? { src: showSystemAppIcon ? "/img/logos/android.svg" : iconUrl, size: "46" }
        : null
    },
    accessor: () => "",
    disableSortBy: true,
    minWidth: "46px",
  },
  {
    id: "name",
    Header: localized("Name"),
    accessor: ({ name, packageName, shouldHideApp }) => getAccessorValue({ shouldHideApp, value: name || packageName }),
    getIconName: ({ shouldHideApp }) => getLockIcon(shouldHideApp),
    minWidth: "250px",
    maxWidth: "250px",
  },
  {
    id: "publisher",
    Header: localized("Publisher"),
    accessor: ({ shouldHideApp, publisher }) => getAccessorValue({ shouldHideApp, value: publisher }),
    getIconName: ({ shouldHideApp }) => getLockIcon(shouldHideApp),
  },
  {
    id: "installType",
    Header: localized("Assignment Type"),
    accessor: ({ installType }) => {
      const token = find(propEq("value", installType), InstallTypeOptions)?.labelToken
      return token ? localized(token) : "-"
    },
  },
  {
    id: "appType",
    Header: localized("App type"),
    accessor: ({ shouldHideApp, appTypeLabel }) => getAccessorValue({ shouldHideApp, value: appTypeLabel }),
    getIconName: ({ shouldHideApp }) => getLockIcon(shouldHideApp),
  },
  {
    id: "packageId",
    Header: localized("Package ID"),
    accessor: ({ shouldHideApp, packageName }) => getAccessorValue({ shouldHideApp, value: packageName }),
    getIconName: ({ shouldHideApp }) => getLockIcon(shouldHideApp),
  },
  {
    id: "connectionName",
    Header: localized("Android Connection"),
    accessor: ({ connectionName, shouldHideApp }) => getAccessorValue({ shouldHideApp, value: connectionName }),
    getIconName: ({ shouldHideApp }) => getLockIcon(shouldHideApp),

    secondaryIconProps: {
      getIconName: ({ connectionName }) => (!!connectionName ? "RegularInfoCircleIconLight" : null),
      getIconTooltipLabel: ({ enterpriseName }) => enterpriseName,
    },
  },
  ...(isChildPolicy ? [getActiveStatusColumn(), getOverrideStateColumn()] : []),
]

const defaultPlayStoreMode = playStoreModeOptions[0].value
const defaultUntrustedAppsOption = untrustedAppsPolicyOptions[0].value
const defaultContentProtectionPolicyOption = contentProtectionPolicyOptions[0].value

const shouldHideAction = ({ connectionId }) => !!connectionId && !canViewConnection(connectionId)

const MobileApplicationsForm = () => {
  const dispatch = useDispatch()
  const { parentPolicy = {}, applications = {}, locale } = useSelector(state => ({
    parentPolicy: state.policyEditor?.parentPolicy,
    applications: state.policyEditor?.policy?.content?.applications,
    locale: state.application?.locale,
  }))
  const isSingleAppKioskEnabled = checkKioskAppExist(applications.settingsByApplication)

  const canAddApplications = false

  const [loadingAppsMetadata, setLoadingAppsMetadata] = useMountedState(false)
  const [loadingAndroidConnections, setLoadingAndroidConnections] = useMountedState(false)
  const [appsMetadata, setAppsMetadata] = useMountedState([])
  const [androidEnterpriseConnections, setAndroidEnterpriseConnections] = useMountedState([])

  const loadMetadata = useCallback(async () => {
    setLoadingAppsMetadata(true)
    const metadata = await getApplicationMetadata({
      apps: applications.settingsByApplication,
      androidEnterpriseConnections,
      defaultConnectionId: androidEnterpriseConnections[0].id,
      locale,
    })
    setLoadingAppsMetadata(false)
    setAppsMetadata(metadata)
  }, [
    androidEnterpriseConnections,
    applications.settingsByApplication,
    locale,
    setAppsMetadata,
    setLoadingAppsMetadata,
  ])

  const loadEnterpriseInfo = useCallback(async () => {
    if (!isAndroidMDMAppEnabled()) {
      return
    }
    setLoadingAndroidConnections(true)
    try {
      const data = await fetchAndroidConnections()
      setAndroidEnterpriseConnections(data)
    } catch (error) {
      reportErrorAndShowMessage(error, localizationKey("No Android Enterprise Connections were found"))
    } finally {
      setLoadingAndroidConnections(false)
    }
  }, [setAndroidEnterpriseConnections, setLoadingAndroidConnections])

  const updateSettingPolicy = curry((settingKey, valueKey, value) => {
    const newValue = assocPath(valueKey.split("."), value, applications[settingKey])

    dispatch(updatePolicyItem(`applications.${settingKey}`, parentPolicy, newValue))
  })

  const updateApplicationPolicy = editedApp => {
    dispatch(
      updatePolicyItem(
        `applications.settingsByApplication.${formatApplicationId(editedApp.packageName)}`,
        parentPolicy,
        formatAppToPolicy(editedApp),
      ),
    )
  }

  const showEditModal = async app => {
    if (app.applicationSource === ApplicationSources.SYSTEM_APP) {
      showModal(
        <SystemAppModal
          {...{ app }}
          onSave={editedApp => {
            updateApplicationPolicy(mergeLeft(editedApp, app))
          }}
        />,
        {
          withProvider: true,
        },
      )
      return
    }

    // Private or web apps should only display respective connection
    const appType = getPlayStoreAppType(app)
    const androidConnectionsOptions =
      appType?.includes(PlayStoreAppTypes.PRIVATE_APP) || appType?.includes(PlayStoreAppTypes.WEB_APP)
        ? [androidEnterpriseConnections.find(propEq("id", app.connectionId))]
        : androidEnterpriseConnections

    const {
      settingsByApplication,
      kioskCustomLauncherSettings: { kioskCustomLauncherEnabled = false },
    } = applications

    showModal(
      <MobileApplicationsPolicyModal
        {...{
          app,
          appConnectionId: app?.connectionId ?? androidEnterpriseConnections[0].id,
          onUpdateApplicationPolicy: updateApplicationPolicy,
          kioskCustomLauncherEnabled,
          applicationsList: values(settingsByApplication),
          androidEnterpriseConnections: androidConnectionsOptions,
        }}
      />,
      {
        withProvider: true,
      },
    )
  }

  const removeApplicationPolicy = selectedApps => {
    const selectedAppsCanUpdate = selectedApps.filter(
      ({ connectionId }) =>
        // Users can't update apps that have hidden actions
        !shouldHideAction({ connectionId }),
    )

    showModal(
      <DeleteAppModal
        selectedApps={mapApplicationsId(selectedAppsCanUpdate)}
        osType="Android"
        updateApplicationsPolicy={apps => {
          for (const { packageName } of selectedAppsCanUpdate) {
            dispatch(deletePolicyItem(`applications.settingsByApplication.${formatApplicationId(packageName)}`))
          }
        }}
        appPolicy={{ applications: mapApplicationsId(values(applications.settingsByApplication)) }}
      />,
    )
  }

  useEffect(() => {
    loadEnterpriseInfo()
  }, [loadEnterpriseInfo])

  useEffect(() => {
    if (appsMetadata.length === 0 && androidEnterpriseConnections.length > 0) {
      loadMetadata()
    }
  }, [androidEnterpriseConnections.length, appsMetadata.length, loadMetadata])

  const formatAppToPolicy = appData => {
    const { applicationSource, name, publisher } = appData
    const systemAppData = applicationSource === ApplicationSources.SYSTEM_APP ? { name, publisher } : {}

    const resultApp = pickAppPolicyFields(appData)

    return {
      active: appData.active ?? true,
      disabled: !!appData.disabled,
      ...resultApp,
      ...systemAppData,
      canBeManaged: !!appData.canBeManaged,
      permissionGrantSettings: appData?.permissionGrantSettings || {},
    }
  }

  const onSaveApp = async newApplication => {
    const newApp = formatAppToPolicy({ ...newApplication, ...defaultInheritance })
    const uniqueId = formatApplicationId(newApp.packageName)

    let appMetadata = {}

    if (newApplication.applicationSource !== ApplicationSources.SYSTEM_APP) {
      appMetadata = await getApplicationByPackageName(newApp.packageName, newApp.connectionId)
      setAppsMetadata(prevAppsMetadata => [appMetadata, ...prevAppsMetadata])
    }

    const playStoreAppType = newApp.packageName
      ? getPlayStoreAppType({
          packageName: newApp.packageName,
          distributionChannel: appMetadata?.distributionChannel,
        })
      : null
    const connectionId = includes(playStoreAppType, [PlayStoreAppTypes.WEB_APP, PlayStoreAppTypes.PRIVATE_APP])
      ? newApp.connectionId
      : null

    dispatch(
      updatePolicyItem(
        `applications.settingsByApplication.${uniqueId}`,
        parentPolicy,
        // Public apps should not have the entry `connectionId`
        cleanSettingsData({
          connectionId,
          ...omit(["connectionId"], newApp),
          ...(appMetadata?.distributionChannel && { distributionChannel: appMetadata.distributionChannel }),
        }),
      ),
    )
    showSuccessMessage(
      localized(`{{appName}} was added to the list`, {
        appName: appMetadata?.title || newApp.packageName,
      }),
    )
  }

  const showAddApp = ({ isSystemApp } = {}) => {
    if (isSystemApp) {
      showModal(
        <SystemAppModal
          policyAppsList={values(applications.settingsByApplication)}
          onSave={onSaveApp}
          addViaGooglePlay={onSaveApp}
          androidEnterpriseConnections={androidEnterpriseConnections}
        />,
        {
          withProvider: true,
        },
      )
    } else {
      showModal(
        <MobileApplicationsSelectorModal
          {...{ loadingAndroidConnections }}
          onApplicationSelected={onSaveApp}
          applicationPolicy={values(applications.settingsByApplication)}
          androidEnterpriseConnections={androidEnterpriseConnections}
        />,
        {
          withProvider: true,
        },
      )
    }
  }

  const formatApplicationsToRows = (appPolicy = [], metadata = []) => {
    return appPolicy.map(app => {
      const appMetadata = find(propEq("packageName", app.packageName), metadata) ?? {}
      const {
        packageName,
        defaultPermissionPolicy,
        applicationSource,
        author,
        title,
        connectionId,
        ...appData
      } = mergeLeft(appMetadata, app)
      const playStoreAppType = appMetadata.packageName ? getPlayStoreAppType(appMetadata) : null

      const permissionPolicyData = find(
        propEq("value", defaultPermissionPolicy ?? PermissionPolicy.PERMISSION_POLICY_UNSPECIFIED),
        permissionPolicyOptions,
      )

      const androidConnection =
        androidEnterpriseConnections && find(propEq("id", connectionId))(androidEnterpriseConnections)

      const appTypeValue = applicationSource === ApplicationSources.SYSTEM_APP ? applicationSource : playStoreAppType
      const appType = find(propEq("value", appTypeValue), getApplicationTypeOptions())

      const isPrivateOrWebApp =
        appType?.value === PlayStoreAppTypes.PRIVATE_APP || appType?.value === PlayStoreAppTypes.WEB_APP

      const names = applicationSource === ApplicationSources.PLAY_STORE ? { publisher: author, name: title } : {}

      return {
        ...appData,
        ...names,
        playStoreAppType,
        packageName,
        applicationSource,
        updated: true,
        id: packageName,
        permissionPolicyData: permissionPolicyData ? localized(permissionPolicyData.labelToken) : null,
        appTypeLabel: appType?.label,
        connectionName: isPrivateOrWebApp ? androidConnection?.connectionName : null,
        enterpriseName: isPrivateOrWebApp ? androidConnection?.enterpriseDisplayName : "",
        connectionId: app.connectionId,
        // User cannot view app if app actions are hidden
        shouldHideApp: shouldHideAction({ connectionId }),
        defaultPermissionPolicy,
      }
    })
  }

  const changeAppStatus = app => {
    dispatch(
      updatePolicyItem(
        `applications.settingsByApplication.${formatApplicationId(app.packageName)}`,
        parentPolicy,
        formatAppToPolicy({ ...app, active: app.active === false }),
      ),
    )
  }

  const isChildPolicy = isNotNil(parentPolicy)
  const alwaysOnVpnPackageName = path(["alwaysOnVpnPackageSettings", "alwaysOnVpnPackage", "packageName"], applications)

  return (
    <Box overflow="auto" marginLeft={sizer(6)} paddingRight={sizer(3)}>
      <Box marginBottom={sizer(6)}>
        <TitleGroup
          titleToken={localizationKey("Applications")}
          descriptionToken={localizationKey("Manage and define application settings.")}
        />
      </Box>
      <Box marginTop={spacing[4]} marginBottom={spacing[2]}>
        <StyledTitleLabel text={localized("General")} />
        <InheritableRowPolicyItem
          pathToItem="applications.permissionSettings"
          inheritableItem={applications.permissionSettings}
        >
          <FlexRowWrapper>
            <LabelWrapper>
              <Body>{localized("Default permission policy")}</Body>
            </LabelWrapper>
            <SelectWrapper>
              <Select
                options={permissionPolicyOptions}
                value={pathOr(
                  PermissionPolicy.PERMISSION_POLICY_UNSPECIFIED,
                  ["permissionSettings", "defaultPermissionPolicy"],
                  applications,
                )}
                triggerAriaLabel={localized("Permission policy")}
                onChange={updateSettingPolicy("permissionSettings", "defaultPermissionPolicy")}
                alignRight={false}
              />
            </SelectWrapper>
          </FlexRowWrapper>
        </InheritableRowPolicyItem>
        <InheritableRowPolicyItem
          pathToItem="applications.playStoreSettings"
          inheritableItem={applications.playStoreSettings}
        >
          <FlexRowWrapper>
            <LabelWrapper>
              <Body>{localized("Play store mode")}</Body>
            </LabelWrapper>
            <SelectWrapper>
              <Select
                options={playStoreModeOptions}
                value={pathOr(defaultPlayStoreMode, ["playStoreSettings", "playStoreMode"], applications)}
                triggerAriaLabel={localized("Play store mode")}
                onChange={updateSettingPolicy("playStoreSettings", "playStoreMode")}
                alignRight={false}
              />
            </SelectWrapper>
          </FlexRowWrapper>
        </InheritableRowPolicyItem>
        <InheritableRowPolicyItem
          pathToItem="applications.untrustedAppsSettings"
          inheritableItem={applications.untrustedAppsSettings}
        >
          <FlexRowWrapper>
            <LabelWrapper>
              <Body>{localized("Untrusted apps policy")}</Body>
            </LabelWrapper>
            <SelectWrapper>
              <Select
                options={untrustedAppsPolicyOptions}
                value={pathOr(
                  defaultUntrustedAppsOption,
                  ["untrustedAppsSettings", "untrustedAppsPolicy"],
                  applications,
                )}
                triggerAriaLabel={localized("Untrusted apps policy")}
                onChange={updateSettingPolicy("untrustedAppsSettings", "untrustedAppsPolicy")}
                alignRight={false}
              />
            </SelectWrapper>
          </FlexRowWrapper>
        </InheritableRowPolicyItem>
        {/* TODO: this item will be removed on task NJ-90658 */}
        <InheritableRowPolicyItem
          pathToItem="applications.contentProtectionPolicySettings"
          inheritableItem={applications.contentProtectionPolicySettings ?? defaultInheritance}
        >
          <FlexRowWrapper>
            <LabelWrapper>
              <Body>{localized("Content protection policy")}</Body>
            </LabelWrapper>
            <SelectWrapper>
              <Select
                triggerAriaLabel={localized("Content protection policy")}
                options={contentProtectionPolicyOptions}
                value={pathOr(
                  defaultContentProtectionPolicyOption,
                  ["contentProtectionPolicySettings", "contentProtectionPolicy"],
                  applications,
                )}
                onChange={updateSettingPolicy("contentProtectionPolicySettings", "contentProtectionPolicy")}
              />
            </SelectWrapper>
          </FlexRowWrapper>
        </InheritableRowPolicyItem>
        <InheritableRowPolicyItem
          pathToItem="applications.kioskCustomLauncherSettings"
          inheritableItem={applications.kioskCustomLauncherSettings}
          wrapperProps={{ padding: `${spacing[7]} ${spacing[2]}` }}
          noBorder
        >
          <Switch
            labelText={localized("Native multi-app kiosk launcher")}
            checked={!!path(["kioskCustomLauncherSettings", "kioskCustomLauncherEnabled"], applications)}
            onChange={updateSettingPolicy("kioskCustomLauncherSettings", "kioskCustomLauncherEnabled")}
            disabled={isSingleAppKioskEnabled}
            tooltip={
              isSingleAppKioskEnabled
                ? localized(
                    "Multi-app kiosk mode cannot be enabled because of a managed application assigned as kiosk mode",
                  )
                : ""
            }
          />
        </InheritableRowPolicyItem>

        <GroupHeading
          textToken={localizationKey("Always-on VPN")}
          inheritance={{
            pathToItem: `applications.alwaysOnVpnPackageSettings`,
            inheritableItem: applications?.alwaysOnVpnPackageSettings ?? defaultInheritance,
          }}
        />

        <FieldRow>
          <LabelWrapper>
            <Body color="colorTextStrong" textWrap textWrapLineLimit={2}>
              {localized("Configure always-on VPN package")}
            </Body>
          </LabelWrapper>
          <SelectWrapper>
            <Input
              ariaLabel={localized("Always-on VPN package")}
              name="alwaysOnVpn-packageName"
              placeholder={localized("Input package name")}
              value={alwaysOnVpnPackageName ?? ""}
              onChange={e => {
                const packageName = e.target.value.trim()
                updateSettingPolicy(
                  "alwaysOnVpnPackageSettings",
                  "alwaysOnVpnPackage",
                  packageName.length > 0
                    ? {
                        packageName,
                        lockdownEnabled: path(
                          ["alwaysOnVpnPackageSettings", "alwaysOnVpnPackage", "lockdownEnabled"],
                          applications,
                        ),
                      }
                    : { packageName: null, lockdownEnabled: false },
                )
              }}
              errorMessage={
                isInputEmptyOrHasValidPackageName(alwaysOnVpnPackageName ?? "")
                  ? ""
                  : localized(
                      "The package name must have at least two segments, each segment must start with a letter and all characters must be alphanumeric or an underscore.",
                    )
              }
            />
          </SelectWrapper>
        </FieldRow>

        <FieldRow>
          <Switch
            labelText={localized("Block network access if VPN disconnects")}
            checked={!!path(["alwaysOnVpnPackageSettings", "alwaysOnVpnPackage", "lockdownEnabled"], applications)}
            onChange={value =>
              updateSettingPolicy("alwaysOnVpnPackageSettings", "alwaysOnVpnPackage.lockdownEnabled", value)
            }
            disabled={isNil(alwaysOnVpnPackageName)}
            tooltip={
              isNil(alwaysOnVpnPackageName) ? localized("A package name is required to block network access.") : null
            }
          />
        </FieldRow>

        <StyledTitleLabel text={localized("Managed Applications")} marginTop={spacing[3]} />

        {loadingAndroidConnections || androidEnterpriseConnections?.length > 0 ? (
          <Box height="500px" width="100%">
            <DataTable
              isShowSelectedEnabled
              tableId="mobileapplications-table"
              pageSizeLimit={20}
              loading={loadingAppsMetadata || loadingAndroidConnections}
              rows={formatApplicationsToRows(values(applications.settingsByApplication), appsMetadata)}
              columns={columns(isChildPolicy)}
              getCustomRowProps={({ connectionId }) => ({
                disabled: shouldHideAction({ connectionId }),
                disabledTooltipLabel: localized("You don't have the permissions to manage this application"),
              })}
              actions={{
                primary: [
                  {
                    labelToken: localizationKey("Revert overrides"),
                    action: compose(
                      forEach(app =>
                        dispatch(
                          revertPolicySection(
                            `applications.settingsByApplication.${formatApplicationId(app.packageName)}`,
                            app,
                          ),
                        ),
                      ),
                      prop("selected"),
                    ),
                    hideMultiAction: any(isNotOverriddenRow),
                    hideRowAction: isNotOverriddenRow,
                  },
                  ...getStatusActions(changeAppStatus, isChildPolicy),
                  {
                    labelToken: localizationKey("Edit"),
                    action: ({ selected: [app] }) => showEditModal(app),
                    hideMultiAction: rows => rows.length > 1,
                    splitAfter: true,
                    hideRowAction: shouldHideAction,
                  },
                  {
                    labelToken: localizationKey("Remove"),
                    hideMultiAction: any(isInheritedRow),
                    hideRowAction: app => isInheritedRow(app) || shouldHideAction(app),
                    isRed: true,
                    action: ({ selected }) => removeApplicationPolicy(selected),
                    variant: "danger",
                  },
                ],
              }}
              noRowsToken={
                canAddApplications
                  ? localizationKey("No packages installed")
                  : localizationKey("Android integration not configured")
              }
              globalActionsButton={{
                buttonProps: {
                  labelToken: localizationKey("Add apps"),
                  variant: "secondary",
                },
                actions: [
                  {
                    labelToken: localizationKey("Play Store"),
                    action: showAddApp,
                  },
                  {
                    labelToken: localizationKey("System app"),
                    action: () => showAddApp({ isSystemApp: true }),
                  },
                ],
              }}
            />
          </Box>
        ) : (
          <Box marginTop={spacing[4]} marginBottom={spacing[8]}>
            {// Can create connections but there are no connections
            canCreateMDMConnection(MDMPermissionType.Android) && androidEnterpriseConnections?.length === 0 ? (
              <AlertMessage titleToken={addAndroidConnectionsAlertMessage.title}>
                {addAndroidConnectionsAlertMessage.message()}
              </AlertMessage>
            ) : (
              // Cannot view or create any MDM connections
              <AlertMessage titleToken={alertMessageTokens.title}>{alertMessageTokens.message()}</AlertMessage>
            )}
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default MobileApplicationsForm
