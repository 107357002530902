export const createTicket = `
  mutation CreateTicket(
    $ticket: NewTicket!
  ) {
    createTicket(ticket: $ticket) {
      id
    }
  }
`

export const updateTicket = `
  mutation UpdateTicket(
    $ticketId: Int!,
    $ticket: UpdateTicket,
    $comment : NewTicketLogEntry
  ) {
    updateTicket( ticket: $ticket, ticketId: $ticketId, comment: $comment) {
      id
      agreement {
        id
        name
      }
      client {
        id
        name
      }
      location {
        id
        name
      }
      node {
        id
      }
      requester {
        id
        uid
        email
        firstName
        lastName
        entityType
      }
      summary
      status {
        parentId
        statusId
        displayName
      }
      type
      priority
      severity
      totalTimeTracked
      parentTicket {
        id
        summary
        createTime
        status {
          parentId
          statusId
          displayName
        }
      }
      assignedAppUser {
        id
        uid
        email
        firstName
        lastName
      }
      ccList {
        uids
        emails
      }
      attributeValues {
        attributeId
        value
      }
      ticketFormId
      tags
      triggeredCondition {
        message
        uid
      }
      deleted
      version
    }
  }
`

export const ticketLogEntryById = `
  query GetTicketLogEntryById($ticketLogEntryId: Int!){
    ticketLogEntryById(id: $ticketLogEntryId){
      user {
        firstName
        lastName
        email
      }
      system
      body
      htmlBody
      createTime
      publicEntry
      techniciansTaggedMetadata {
        id
        email
        displayName
        permitted
        deleted
      }
    }
  }
`

export const updateTickets = `
  mutation UpdateTicketSet($updateTicketSet: UpdateTicketSet!){
    updateTickets(updateTicketSet: $updateTicketSet)
  }
`

export const deleteTickets = `
  mutation DeleteTickets($ticketIds: [Int]!){
    deleteTickets(ticketIds: $ticketIds)
  }
`

export const updateTimeTracked = `
  mutation UpdateTimeTracked($ticketLogEntryId: Int!, $ticketTimeEntryRequest: TicketTimeEntryRequest!){
    updateTimeTracked(ticketLogEntryId: $ticketLogEntryId, ticketTimeEntryRequest: $ticketTimeEntryRequest){
      id
    }
  }
`
