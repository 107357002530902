import { faCheckSquare } from "@fortawesome/pro-light-svg-icons"
import {
  faAlignJustify,
  faAt,
  faBrowser,
  faCalendar,
  faCalendarDay,
  faClock,
  faHashtag,
  faListAlt,
  faListUl,
  faLock,
  faNewspaper,
  faPaperclip,
  faPhone,
  faPhoneLaptop,
  faSitemap,
  faText,
} from "@fortawesome/pro-solid-svg-icons"
import { nanoid } from "nanoid"
import { compose, count, isNil, map, reduce, reject, values } from "ramda"

import { isUserAllowedToAccessSecureFields } from "js/includes/common/services/userPermissions"
import {
  dateTz,
  getLocalizedOption,
  getLocalizedOptionV2,
  getValidationSuccess,
  localizationKey,
  localized,
  localizedF,
  sanitizeHTML,
  sortByFieldNameCaseInsensitive,
  time,
} from "js/includes/common/utils"
import { getAdvancedSettingsKeyValuePair, getAttributeScope } from "js/includes/components/CustomFields/common"
import { PhoneFormFilter } from "js/includes/components/CustomFields/FilterRenderer"
import { FormDescription, FormSeparator, FormTitle } from "js/includes/components/CustomFields/FormElements"
import {
  AttachmentFormField,
  CheckboxFormField,
  CustomerDropdownFormField,
  CustomerLocationDropdownFormField,
  CustomerLocationMultiSelectFormField,
  CustomerMultiSelectFormField,
  DateFormField,
  DateTimeFormField,
  DecimalFormField,
  DeviceDropdownFormField,
  DeviceMultiSelectFormField,
  DropdownFormField,
  EmailFormField,
  IpAddressFormField,
  MultiSelectFormField,
  NumericFormField,
  PhoneFormField,
  TextAreaFormField,
  TextEncryptedFormField,
  TextFormField,
  TimeFormField,
  UrlFormField,
  WYSIWYGFormField,
} from "js/includes/components/CustomFields/FormFields"
import {
  AttachmentValueRenderer,
  CheckboxValueRenderer,
  DateTimeValueRenderer,
  DateValueRenderer,
  DropdownValueRenderer,
  EmailValueRenderer,
  IpAddressValueRenderer,
  MultipleCustomerLocationValueRenderer,
  MultipleCustomerValueRenderer,
  MultipleDevicesValueRenderer,
  MultiSelectValueRenderer,
  NumericValueRenderer,
  PhoneValueRenderer,
  SingleCustomerLocationValueRenderer,
  SingleCustomerValueRenderer,
  SingleDeviceValueRenderer,
  TextEncryptedValueRenderer,
  TextMultilineValueRenderer,
  TextValueRenderer,
  TimeValueRenderer,
  UrlValueRenderer,
  WYSIWYGValueRenderer,
} from "js/includes/components/CustomFields/ValueRenderers"

import {
  COMPLEXITY_RULES_SELECTOR,
  DATE_FILTERS,
  DATE_TIME_FILTERS,
  DEVICE_ROLES_SELECTOR,
  EXPAND_LARGE_VALUE_ON_RENDER,
  FILE_EXTENSIONS_SELECTOR,
  FILE_MAX_SIZE_MB,
  IP_ADDRESS_TYPE_SELECTOR,
  MAX_CHARACTERS,
  MULTI_OPTIONS_FIELD,
  NUMERIC_RANGE_SELECTOR,
  ORGANIZATION_SELECTOR,
} from "./common/advancedSettings"
import { NONE, READ_ONLY, READ_WRITE, WRITE_ONLY } from "./common/fieldPermissions"
import { getFieldTypeOperators } from "./common/operators"
import { validations } from "./common/validation"
import { WYSIWYG_MAX_ALLOWANCE } from "js/includes/common/_constants"
import WYSIWYGFormFilter from "js/includes/components/CustomFields/FilterRenderer/WYSIWYGFormFilter"
import { isNullOperator } from "js/includes/components/FilterModals/CustomFields/utils"

export const NONE_READ_ONLY_OPTIONS = [getLocalizedOption(NONE), getLocalizedOption(READ_ONLY)]

export const CHECKBOX = {
  value: "CHECKBOX",
  token: localizationKey("Checkbox"),
  icon: faCheckSquare,
  renderFormField: CheckboxFormField,
  operators: getFieldTypeOperators("CHECKBOX"),
  getInitialValue: (field, value) => (!isNil(value) ? value : field.defaultValue || null),
  renderValue: CheckboxValueRenderer,
  getFilterFormLabel: localizedF("Checkbox value"),
}

export const DATE = {
  value: "DATE",
  token: localizationKey("Date"),
  icon: faCalendarDay,
  renderFormField: DateFormField,
  operators: getFieldTypeOperators("DATE"),
  getInitialValue: (field, value) => (!isNil(value) ? value : field.defaultValue || null),
  renderValue: DateValueRenderer,
  getValidation: validations.date,
  advancedSettings: [DATE_FILTERS],
  shouldFilterValue: ({ value, text }) => {
    const regex = new RegExp(text, "g")
    return value && regex.test(dateTz(value, "L"))
  },
  getFilterFormLabel: localizedF("Date"),
}

export const TIME = {
  value: "TIME",
  token: localizationKey("Time"),
  icon: faClock,
  renderFormField: TimeFormField,
  operators: getFieldTypeOperators("TIME"),
  getInitialValue: (field, value) => (!isNil(value) ? value : field.defaultValue || null),
  renderValue: TimeValueRenderer,
  shouldFilterValue: ({ value, text }) => {
    const regex = new RegExp(text, "g")
    return value && regex.test(time(value))
  },
  getFilterFormLabel: localizedF("Time"),
}

export const DATE_TIME = {
  value: "DATE_TIME",
  token: localizationKey("Date/Time"),
  icon: faCalendar,
  renderFormField: DateTimeFormField,
  operators: getFieldTypeOperators("DATE_TIME"),
  getInitialValue: (field, value) => (!isNil(value) ? value : field.defaultValue || null),
  renderValue: DateTimeValueRenderer,
  getValidation: validations.date,
  advancedSettings: [DATE_TIME_FILTERS],
  shouldFilterValue: ({ value, text }) => {
    const regex = new RegExp(text, "g")
    return value && regex.test(dateTz(value, "L LT"))
  },
  getFilterFormLabel: localizedF("Date & time"),
}

export const DROPDOWN = {
  value: "DROPDOWN",
  token: localizationKey("Drop-down"),
  icon: faListAlt,
  renderFormField: DropdownFormField,
  operators: getFieldTypeOperators("DROPDOWN"),
  getInitialValue: (field, value) => (!isNil(value) ? value : field.defaultValue || null),
  renderValue: DropdownValueRenderer,
  advancedSettings: [MULTI_OPTIONS_FIELD],
  shouldFilterValue: ({ field, value, text }) => {
    const regex = new RegExp(text, "g")
    return (
      value &&
      regex.test(
        field.options.find(option => {
          return option.id === value
        })?.name ?? "",
      )
    )
  },
  getFilterFormLabel: field => field?.name ?? "",
}

export const MULTI_SELECT = {
  value: "MULTI_SELECT",
  token: localizationKey("Multi-select"),
  icon: faListUl,
  renderFormField: MultiSelectFormField,
  operators: getFieldTypeOperators("MULTI_SELECT"),
  getInitialValue: (field, value) => (!isNil(value) ? value : field.defaultValue || null),
  renderValue: MultiSelectValueRenderer,
  advancedSettings: [MULTI_OPTIONS_FIELD],
  shouldFilterValue: ({ field, value, text }) => {
    const regex = new RegExp(text, "g")
    return value && field.options.filter(option => value?.includes(option.id)).some(option => regex.test(option.name))
  },
  getFilterFormLabel: field => field?.name ?? "",
}

export const NUMERIC = {
  value: "NUMERIC",
  token: localizationKey("Integer"),
  icon: faHashtag,
  renderFormField: NumericFormField,
  operators: getFieldTypeOperators("NUMERIC"),
  getInitialValue: (field, value) => (!isNil(value) ? value : field.defaultValue || null),
  renderValue: NumericValueRenderer,
  getValidation: validations.integer,
  advancedSettings: [NUMERIC_RANGE_SELECTOR({ enableDecimal: false })],
  shouldFilterValue: ({ value, text }) => {
    const regex = new RegExp(text, "g")
    return value && regex.test(value.toString())
  },
  getFilterFormLabel: localizedF("Integer"),
}

export const DECIMAL = {
  value: "DECIMAL",
  token: localizationKey("Decimal"),
  icon: faHashtag,
  renderFormField: DecimalFormField,
  operators: getFieldTypeOperators("DECIMAL"),
  getInitialValue: (field, value) => (!isNil(value) ? value : field.defaultValue || null),
  renderValue: NumericValueRenderer,
  getValidation: validations.decimal,
  advancedSettings: [NUMERIC_RANGE_SELECTOR({ enableDecimal: true })],
  shouldFilterValue: ({ value, text }) => {
    const regex = new RegExp(text, "g")
    return value && regex.test(value.toString())
  },
  getFilterFormLabel: localizedF("Decimal"),
}

export const TEXT = {
  value: "TEXT",
  token: localizationKey("Text"),
  icon: faText,
  renderFormField: TextFormField,
  operators: getFieldTypeOperators("TEXT"),
  getInitialValue: (field, value) => (!isNil(value) ? value : field.defaultValue || null),
  renderValue: TextValueRenderer,
  shouldFilterValue: ({ value, text }) => {
    const regex = new RegExp(text.toLowerCase(), "g")
    return value && regex.test(value.toLowerCase())
  },
  getFilterFormLabel: localizedF("Text"),
}

export const TEXT_MULTILINE = {
  value: "TEXT_MULTILINE",
  token: localizationKey("Multi-line"),
  icon: faAlignJustify,
  renderFormField: TextAreaFormField,
  operators: getFieldTypeOperators("TEXT_MULTILINE"),
  getInitialValue: (field, value) => (!isNil(value) ? value : field.defaultValue || null),
  renderValue: TextMultilineValueRenderer,
  shouldFilterValue: ({ value, text }) => {
    const regex = new RegExp(text.toLowerCase(), "g")
    return value && regex.test(value.toLowerCase())
  },
  getFilterFormLabel: localizedF("Text"),
}

export const TEXT_ENCRYPTED = {
  value: "TEXT_ENCRYPTED",
  token: localizationKey("Secure"),
  icon: faLock,
  disabled: entityType => !isUserAllowedToAccessSecureFields(entityType),
  renderFormField: TextEncryptedFormField,
  operators: getFieldTypeOperators("TEXT_ENCRYPTED"),
  getInitialValue: (field, value) => (!isNil(value) ? value : null),
  renderValue: TextEncryptedValueRenderer,
  getValidation: validations.secureText,
  advancedSettings: [MAX_CHARACTERS, COMPLEXITY_RULES_SELECTOR],
  scriptPermissionOptions: [
    getLocalizedOption(NONE),
    getLocalizedOption(WRITE_ONLY),
    getLocalizedOption(READ_ONLY),
    getLocalizedOption(READ_WRITE),
  ],
  shouldFilterValue: () => false,
}

export const ATTACHMENT = {
  value: "ATTACHMENT",
  token: localizationKey("Attachment"),
  icon: faPaperclip,
  renderFormField: AttachmentFormField,
  operators: getFieldTypeOperators("ATTACHMENT"),
  getInitialValue: (field, value) => (!isNil(value) ? value : null),
  renderValue: AttachmentValueRenderer,
  advancedSettings: [FILE_MAX_SIZE_MB, FILE_EXTENSIONS_SELECTOR],
  scriptPermissionOptions: NONE_READ_ONLY_OPTIONS,
  shouldFilterValue: ({ value, text }) => {
    const regex = new RegExp(text, "g")
    return value && regex.test(value.name)
  },
}

export const NODE_DROPDOWN = {
  value: "NODE_DROPDOWN",
  token: localizationKey("Device dropdown"),
  icon: faPhoneLaptop,
  renderFormField: DeviceDropdownFormField,
  operators: getFieldTypeOperators("NODE_DROPDOWN"),
  getInitialValue: (field, value) => (!isNil(value) ? value : null),
  renderValue: SingleDeviceValueRenderer,
  advancedSettings: [ORGANIZATION_SELECTOR, DEVICE_ROLES_SELECTOR],
  scriptPermissionOptions: NONE_READ_ONLY_OPTIONS,
  getFilterFormLabel: localizedF("Device"),
}

export const NODE_MULTI_SELECT = {
  value: "NODE_MULTI_SELECT",
  token: localizationKey("Device multi-select"),
  icon: faPhoneLaptop,
  renderFormField: DeviceMultiSelectFormField,
  operators: getFieldTypeOperators("NODE_MULTI_SELECT"),
  getInitialValue: (field, value) => (!isNil(value) ? value : null),
  renderValue: MultipleDevicesValueRenderer,
  advancedSettings: [ORGANIZATION_SELECTOR, DEVICE_ROLES_SELECTOR],
  scriptPermissionOptions: NONE_READ_ONLY_OPTIONS,
  getFilterFormLabel: localizedF("Devices"),
}

export const CLIENT_DROPDOWN = {
  value: "CLIENT_DROPDOWN",
  token: localizationKey("Organization dropdown"),
  icon: faSitemap,
  renderFormField: CustomerDropdownFormField,
  operators: getFieldTypeOperators("CLIENT_DROPDOWN"),
  getInitialValue: (field, value) => (!isNil(value) ? value : null),
  renderValue: SingleCustomerValueRenderer,
  scriptPermissionOptions: NONE_READ_ONLY_OPTIONS,
  getFilterFormLabel: localizedF("Organization"),
}

export const CLIENT_MULTI_SELECT = {
  value: "CLIENT_MULTI_SELECT",
  token: localizationKey("Organization multi-select"),
  icon: faSitemap,
  renderFormField: CustomerMultiSelectFormField,
  operators: getFieldTypeOperators("CLIENT_MULTI_SELECT"),
  getInitialValue: (field, value) => (!isNil(value) ? value : null),
  renderValue: MultipleCustomerValueRenderer,
  scriptPermissionOptions: NONE_READ_ONLY_OPTIONS,
  getFilterFormLabel: localizedF("Organizations"),
}

export const CLIENT_LOCATION_DROPDOWN = {
  value: "CLIENT_LOCATION_DROPDOWN",
  token: localizationKey("Organization Location dropdown"),
  icon: faSitemap,
  renderFormField: CustomerLocationDropdownFormField,
  operators: getFieldTypeOperators("CLIENT_LOCATION_DROPDOWN"),
  getInitialValue: (field, value) => (!isNil(value) ? value : null),
  renderValue: SingleCustomerLocationValueRenderer,
  advancedSettings: [ORGANIZATION_SELECTOR],
  scriptPermissionOptions: NONE_READ_ONLY_OPTIONS,
  getFilterFormLabel: localizedF("Organization location"),
}

export const CLIENT_LOCATION_MULTI_SELECT = {
  value: "CLIENT_LOCATION_MULTI_SELECT",
  token: localizationKey("Organization Location multi-select"),
  icon: faSitemap,
  renderFormField: CustomerLocationMultiSelectFormField,
  operators: getFieldTypeOperators("CLIENT_LOCATION_MULTI_SELECT"),
  getInitialValue: (field, value) => (!isNil(value) ? value : null),
  renderValue: MultipleCustomerLocationValueRenderer,
  advancedSettings: [ORGANIZATION_SELECTOR],
  scriptPermissionOptions: NONE_READ_ONLY_OPTIONS,
  getFilterFormLabel: localizedF("Organization locations"),
}

export const EMAIL = {
  value: "EMAIL",
  token: localizationKey("Email"),
  icon: faText,
  renderFormField: EmailFormField,
  operators: getFieldTypeOperators("TEXT"),
  getInitialValue: (field, value) => (!isNil(value) ? value : field.defaultValue || null),
  renderValue: EmailValueRenderer,
  getValidation: () => validations.email,
  shouldFilterValue: ({ value, text }) => {
    const regex = new RegExp(text.toLowerCase(), "g")
    return value && regex.test(value.toLowerCase())
  },
  getFilterFormLabel: localizedF("Email"),
}

export const PHONE = {
  value: "PHONE",
  token: localizationKey("Phone"),
  icon: faPhone,
  renderFormField: PhoneFormField,
  operators: getFieldTypeOperators("TEXT"),
  getInitialValue: (field, value) => (!isNil(value) ? value : field.defaultValue || null),
  renderValue: PhoneValueRenderer,
  getValidation: () => validations.phone,
  renderFilter: PhoneFormFilter,
  shouldFilterValue: ({ value, text }) => {
    const regex = new RegExp(text, "g")
    return value && regex.test(value.toString())
  },
  getFilterFormLabel: localizedF("Phone number"),
}

export const IP_ADDRESS = {
  value: "IP_ADDRESS",
  token: localizationKey("IP Address"),
  icon: faAt,
  renderFormField: IpAddressFormField,
  operators: getFieldTypeOperators("TEXT"),
  getInitialValue: (field, value) => (!isNil(value) ? value : field.defaultValue || null),
  renderValue: IpAddressValueRenderer,
  getValidation: validations.ipAddress,
  advancedSettings: [IP_ADDRESS_TYPE_SELECTOR],
  shouldFilterValue: ({ value, text }) => {
    const regex = new RegExp(text, "g")
    return value && regex.test(value)
  },
  getFilterFormLabel: localizedF("IP Address"),
}

export const WYSIWYG = {
  value: "WYSIWYG",
  token: localizationKey("WYSIWYG"),
  icon: faNewspaper,
  renderFormField: WYSIWYGFormField,
  renderFilter: WYSIWYGFormFilter,
  operators: getFieldTypeOperators("WYSIWYG"),
  getInitialValue: (field, value) => {
    return !isNil(value) ? value : field.defaultValue || null
  },
  renderValue: WYSIWYGValueRenderer,
  getValidation: validations.wysiwyg,
  advancedSettings: [EXPAND_LARGE_VALUE_ON_RENDER],
  shouldFilterValue: ({ value, text }) => {
    const regex = new RegExp(text.toLowerCase(), "g")
    return value && regex.test(value.text.toLowerCase())
  },
  getFilterFormLabel: localizedF("Text"),
}

export const URL = {
  value: "URL",
  token: localizationKey("URL"),
  icon: faBrowser,
  renderFormField: UrlFormField,
  operators: getFieldTypeOperators("TEXT"),
  getInitialValue: (field, value) => value ?? (field.defaultValue || null),
  renderValue: UrlValueRenderer,
  getValidation: () => validations.url,
  shouldFilterValue: ({ value, text }) => {
    const regex = new RegExp(text, "g")
    return value && regex.test(value)
  },
  getFilterFormLabel: localizedF("URL"),
}

export const fieldTypes = {
  ATTACHMENT,
  CHECKBOX,
  DATE,
  TIME,
  DATE_TIME,
  NUMERIC,
  DECIMAL,
  DROPDOWN,
  NODE_DROPDOWN,
  CLIENT_DROPDOWN,
  CLIENT_LOCATION_DROPDOWN,
  MULTI_SELECT,
  NODE_MULTI_SELECT,
  CLIENT_MULTI_SELECT,
  CLIENT_LOCATION_MULTI_SELECT,
  TEXT,
  TEXT_MULTILINE,
  TEXT_ENCRYPTED,
  EMAIL,
  PHONE,
  IP_ADDRESS,
  WYSIWYG,
  URL,
}

export const fieldTypeOptions = (rejected = []) =>
  compose(
    sortByFieldNameCaseInsensitive("label", "ASC"),
    map(getLocalizedOption),
    reject(({ value }) => rejected.includes(value)),
    values,
  )(fieldTypes)

export const TITLE = {
  value: "TITLE",
  token: localizationKey("Title"),
  renderComponent: FormTitle,
  getInitialValue: (field, value) => (!isNil(value) ? value : ""),
}

export const DESCRIPTION = {
  value: "DESCRIPTION",
  token: localizationKey("Description"),
  renderComponent: FormDescription,
  getInitialValue: (field, value) => (!isNil(value) ? value : ""),
}

export const SEPARATOR = {
  value: "SEPARATOR",
  token: localizationKey("Separator"),
  renderComponent: FormSeparator,
  getInitialValue: (field, value) => (!isNil(value) ? value : null),
}

export const uiElementTypes = {
  DESCRIPTION,
  SEPARATOR,
  TITLE,
}

export const uiElementTypeOptions = () => values(uiElementTypes).map(getLocalizedOptionV2)

export const formatCustomField = data => ({
  tempId: data.tempId || data.id || nanoid(10),
  ...(data.id && { id: data.id }),
  ...(data.createTime && { createTime: data.createTime }),
  ...(data.updateTime && { updateTime: data.updateTime }),
  name: data.fieldName,
  type: data.attributeType,
  label: data.name,
  options: data.content.values,
  entityType: data.entityType,
  attributeScope: data.attributeScope,
  definitionScope: data.definitionScope,
  description: data.description,
  defaultValue: data.defaultValue,
  technicianOption: "OPTIONAL",
  scriptPermission: data.scriptPermission,
  apiPermission: data.apiPermission,
  technicianPermission: data.technicianPermission,
  required: data.content.required,
  footerText: data.content.footerText,
  tooltipText: data.content.tooltipText,
  advancedSettings: fieldTypes[data.attributeType]?.advancedSettings
    ? getAdvancedSettingsKeyValuePair(fieldTypes[data.attributeType]?.advancedSettings, data.content.advancedSettings)
    : null,
})

export const formatCustomUIElement = item => ({
  tempId: item.id || item.tempId || nanoid(10),
  name: item.name,
  uiElementUid: item.uiElementUid,
  uiElementType: item.uiElementType,
  uiElementValue: item.uiElementValue,
  ...(item.uiElementCreateTime && { uiElementCreateTime: item.uiElementCreateTime }),
  ...(item.uiElementUpdateTime && { uiElementUpdateTime: item.uiElementUpdateTime }),
})

export const formatCustomItem = item => (item.uiElementUid ? formatCustomUIElement(item) : formatCustomField(item))

export const formatUIElementForServer = (item, entity, attributeScope) => ({
  ...formatCustomUIElement(item),

  id: null,
  fieldName: null,
  attributeType: null,
  description: null,
  technicianOption: null,
  scriptPermission: null,
  apiPermission: null,
  technicianPermission: null,
  definitionScope: null,
  content: {
    values: [],
  },

  attributeScope: getAttributeScope(entity, attributeScope),
})

export const parseCustomFieldValue = (field, value) => fieldTypes[field.type].getInitialValue?.(field, value)

export const mapCustomFieldToValue = (fields, values = {}) =>
  fields.reduce((acc, field) => {
    if (field.id) {
      acc[field.id] = parseCustomFieldValue(field, values[field.id]?.value)
    }

    return acc
  }, {})

export const mapCustomFieldToServerValue = (fields, values = {}) =>
  fields.reduce((acc, field, index) => {
    if (field.id) {
      acc[field.id] = {
        ...values[field.id],
        attributeId: field.id,
        value: parseCustomFieldValue(field, values[field.id]?.value),
      }
    }

    return acc
  }, {})

export const getEditorInitialValues = (fields, values) => {
  return reduce(
    (acc, field) => {
      if (values[field.id]) {
        acc[field.id] = {
          ...values[field.id],
          value: fieldTypes[field.type]?.getInitialValue(field, values[field.id]?.value),
        }
      }
      return acc
    },
    {},
    fields,
  )
}

const handleFieldValidations = (value, values, validations = []) => {
  for (const validation of validations) {
    const result = validation(value, values)

    if (result?.success === false) {
      return result
    }
  }

  return getValidationSuccess()
}

export const buildFormValidate = fields =>
  reduce(
    (acc, field) => {
      const defaultValidation = fieldTypes[field.type]?.getValidation?.(field) ?? getValidationSuccess
      const isFieldTechnicianPermissionNotReadOnly = field.technicianPermission !== "READ_ONLY"
      const validation = [
        ...(isFieldTechnicianPermissionNotReadOnly && field.required ? [validations.required] : []),
        ...(isFieldTechnicianPermissionNotReadOnly && defaultValidation ? [defaultValidation] : []),
      ]

      if (!!validation.length) {
        acc[field.id] = (value, values) => {
          return handleFieldValidations(value, values, validation)
        }
      }

      return acc
    },
    {},
    fields,
  )

export const getSanitizedHTMLSnippetOptions = field => {
  return field.attributeType === fieldTypes["DROPDOWN"].value
    ? `
        <div style="max-height: 250px;overflow-y: auto;">
        ${
          field.attributeScope === "NODE_ROLE" ? localized("Role Custom Field") : localized("Global Custom Field")
        }: ${sanitizeHTML(field.description) || localized("No description available")}
        <br/><br/>
        <b>${localized("Value Options")}:</b>
        <br/><br/>
        ${field.content.values
          .map(
            (option, i) => `
          ${localized("Name")}: ${option.name}
          <br/>
          ${localized("ID")}: ${option.id}
          <br/><br/>
        `,
          )
          .join("")}
        </div>
      `
    : ""
}

export const isWysiwygField = field => [field.type, field.attributeType].includes(WYSIWYG.value)

export const isGlobalWysiwygField = field => isWysiwygField(field) && field.attributeScope === "NODE_GLOBAL"

export const isRoleOrClassWysiwygField = field => isWysiwygField(field) && field.attributeScope !== "NODE_GLOBAL"

export const hasReachedWysiwygAllowance = (fields, attributeScope) =>
  attributeScope === "global"
    ? count(isGlobalWysiwygField)(fields) >= WYSIWYG_MAX_ALLOWANCE
    : count(isRoleOrClassWysiwygField)(fields) >= WYSIWYG_MAX_ALLOWANCE

export const getConditionDisplayValue = (condition, field, fieldType) => {
  if (isNullOperator(condition)) {
    return null
  }
  switch (fieldType?.value) {
    case fieldTypes.CHECKBOX.value:
    case fieldTypes.DATE.value:
    case fieldTypes.DATE_TIME.value:
    case fieldTypes.NUMERIC.value:
    case fieldTypes.DECIMAL.value:
    case fieldTypes.TIME.value:
      return fieldType.renderValue(field, { value: condition.value?.attributeValue })
    case fieldTypes.EMAIL.value:
    case fieldTypes.IP_ADDRESS.value:
    case fieldTypes.TEXT_MULTILINE.value:
    case fieldTypes.TEXT.value:
    case fieldTypes.PHONE.value:
    case fieldTypes.URL.value:
      return condition.value?.attributeValue
    case fieldTypes.WYSIWYG.value:
      return condition.value?.attributeValue?.text
    case fieldTypes.MULTI_SELECT.value:
      return fieldTypes.MULTI_SELECT.renderValue(
        {
          ...field,
          options: field?.content?.values,
        },
        { value: condition.value?.attributeValue?.split(",") },
      )
    case fieldTypes.DROPDOWN.value:
      return fieldTypes.DROPDOWN.renderValue(
        {
          ...field,
          options: field?.content?.values,
        },
        { value: condition.value?.attributeValue },
      )
    case fieldTypes.NODE_DROPDOWN.value:
    case fieldTypes.NODE_MULTI_SELECT.value:
    case fieldTypes.CLIENT_DROPDOWN.value:
    case fieldTypes.CLIENT_MULTI_SELECT.value:
    case fieldTypes.CLIENT_LOCATION_DROPDOWN.value:
    case fieldTypes.CLIENT_LOCATION_MULTI_SELECT.value:
      return fieldType.renderValue(field, { value: condition.value?.attributeValue }, false)
    default:
      return null
  }
}
