import { useCallback, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { createSelector } from "reselect"
import { __, any, complement, compose, either, forEach, head, includes, pluck, prop, propEq, tail } from "ramda"
import { useMountedState } from "@ninjaone/utils"
import { DataTable, TitleGroup } from "@ninjaone/components"
import { filterTypes } from "@ninjaone/components/src/DataTable"
import { VARIANTS } from "@ninjaone/components/src/Button"
import { spacing } from "@ninjaone/tokens"
import {
  isNotNil,
  localizationKey,
  localized,
  objectWithIdKeysToList,
  reportErrorAndShowMessage,
  showErrorMessage,
  user,
} from "js/includes/common/utils"
import {
  getActiveStatusColumn,
  getOverrideStateColumn,
  getStatusActions,
  isInheritedRow,
  isNotOverriddenRow,
} from "js/includes/editors/Policy/PolicyEditor/tabs/mdm/util"
import { deletePolicyItem, revertPolicySection, updatePolicyItem } from "js/state/actions/policyEditor/editor"
import { Flex } from "js/includes/components/Styled"
import showModal from "js/includes/common/services/showModal"
import { PayloadEditorModal } from "./PayloadEditorModal"
import { getAppleCustomPayloadsMetadata } from "js/includes/common/client"
import { defaultPayloadType, getPayloadTypeOptions, isPayloadMetadataNotLoaded, mapPayloadsWithMetadata } from "./utils"
import { DeletePayloadModal } from "./DeletePayloadModal"

const policySelector = createSelector(
  [
    ({ policyEditor }) => objectWithIdKeysToList(policyEditor.policy.content.customPayloads, "guid"),
    ({ policyEditor }) => policyEditor.parentPolicy,
    ({ policyEditor }) => policyEditor.policy.id,
    ({ policyEditor }) => policyEditor.policy.name,
  ],
  (payloads, parentPolicy, policyId, policyName, savingPolicy) => ({
    payloads,
    parentPolicy,
    policyId,
    policyName,
  }),
)

const getColumns = isChildPolicy => [
  {
    id: "name",
    Header: localized("Name"),
    accessor: "name",
  },
  {
    id: "contentType",
    Header: localized("Payload type"),
    accessor: ({ contentType = [] }) => head(contentType) ?? defaultPayloadType,
    getCellInlineTags: ({ contentType = [] }) => {
      const remainingItems = tail(contentType)
      return remainingItems.length > 0
        ? [
            {
              id: "remaining-items",
              label: localized("+{{remainingItems}} more", { remainingItems: remainingItems.length }),
              tooltipLabel: remainingItems.join("\n"),
            },
          ]
        : null
    },
  },
  ...(isChildPolicy ? [getActiveStatusColumn(), getOverrideStateColumn()] : []),
]

const getFilters = options => ({
  primary: [
    {
      name: "payloadTypeFilter",
      type: filterTypes.MULTISELECT,
      labelToken: localizationKey("Payload type"),
      componentProps: { options },
      filter: ({ value, row: { contentType = [defaultPayloadType] } }) => any(includes(__, contentType), value),
    },
  ],
})

export const AppleCustomPayload = () => {
  const { payloads, parentPolicy, policyId, policyName } = useSelector(policySelector)
  const dispatch = useDispatch()
  const [metadata, setMetadata] = useMountedState([])
  const [loading, setLoading] = useMountedState(false)
  const isChildPolicy = isNotNil(parentPolicy)
  const canUpdatePolicy = user("canUpdatePolicies", policyId)
  // Store payload type options, otherwise there's issues on the data filter if a filter is set but data is removed
  const [payloadTypeOptions, setPayloadTypeOptions] = useMountedState([
    { label: defaultPayloadType, value: defaultPayloadType },
  ])

  const rows = useMemo(() => mapPayloadsWithMetadata(payloads, metadata), [payloads, metadata])
  const columns = useMemo(() => getColumns(isChildPolicy), [isChildPolicy])
  const filters = useMemo(() => getFilters(payloadTypeOptions), [payloadTypeOptions])

  const loadPayloadContent = useCallback(
    async _payloads => {
      setLoading(true)
      try {
        const _metadata = await getAppleCustomPayloadsMetadata({ policyId, payloadIds: pluck("id", _payloads) })
        setMetadata(_metadata)
        setPayloadTypeOptions(getPayloadTypeOptions(_metadata))
      } catch (error) {
        reportErrorAndShowMessage(error, localizationKey("There was an error while loading the custom payloads"))
      } finally {
        setLoading(false)
      }
    },
    [setLoading, policyId, setMetadata, setPayloadTypeOptions],
  )

  useEffect(() => {
    isPayloadMetadataNotLoaded(rows) && loadPayloadContent(rows)
  }, [loadPayloadContent, rows])

  const showPayloadEditorModal = ({ selected } = {}, ...rest2) => {
    const [selectedPayload] = selected ?? []
    showModal(
      <PayloadEditorModal
        {...{ selectedPayload }}
        onSave={(guid, newPayload) => {
          const newPayloadName = newPayload.name.toLowerCase()
          const sameName = selectedPayload?.name.toLowerCase() === newPayloadName
          const duplicated = sameName ? false : rows.some(({ name }) => name.toLowerCase() === newPayloadName)

          if (duplicated) {
            showErrorMessage(localized("The payload name is already in use"))
            return false
          }

          dispatch(updatePolicyItem(`customPayloads.${guid}`, parentPolicy, newPayload))
          return true
        }}
      />,
    )
  }

  const showDeletePayloadModal = ({ selected: payloads }) => {
    showModal(
      <DeletePayloadModal
        {...{
          payloads,
          policyName,
          onConfirm: () => {
            payloads.map(({ guid }) => dispatch(deletePolicyItem(`customPayloads.${guid}`)))
          },
        }}
      />,
    )
  }

  const changeAppStatus = ({ guid, active }) => {
    const payloadFromPolicy = payloads.find(propEq("guid", guid))
    dispatch(
      updatePolicyItem(`customPayloads.${guid}`, parentPolicy, { ...payloadFromPolicy, active: !(active ?? true) }),
    )
  }

  return (
    <Flex marginLeft={spacing[6]} flexDirection="column" gap={spacing[5]} height="100%">
      <TitleGroup
        titleText={localized("Custom payloads")}
        descriptionText={localized("Define MDM payloads directly to include in the policy.")}
      />
      <DataTable
        {...{ columns, rows, loading, filters }}
        onRefresh={() => loadPayloadContent(payloads)}
        tableId="apple-custompayloads-table"
        globalActionsButton={{
          buttonProps: {
            labelToken: localizationKey("Add payload"),
            action: showPayloadEditorModal,
          },
        }}
        noRowsToken={localizationKey("No custom payloads found")}
        actions={
          canUpdatePolicy
            ? {
                primary: [
                  {
                    labelToken: localizationKey("Revert overrides"),
                    action: compose(
                      forEach(payload => dispatch(revertPolicySection(`customPayloads.${payload.guid}`, payload))),
                      prop("selected"),
                    ),
                    hideMultiAction: any(isNotOverriddenRow),
                    hideRowAction: either(complement(isInheritedRow), isNotOverriddenRow),
                  },
                  ...getStatusActions(changeAppStatus, isChildPolicy),
                  {
                    labelToken: localizationKey("Edit"),
                    action: showPayloadEditorModal,
                    hideRowAction: isInheritedRow,
                    hideMultiAction: either(any(isInheritedRow), rows => rows.length > 1),
                    splitAfter: true,
                  },
                  {
                    labelToken: localizationKey("Delete"),
                    variant: VARIANTS.DANGER,
                    action: showDeletePayloadModal,
                    hideRowAction: isInheritedRow,
                    hideMultiAction: any(isInheritedRow),
                    isRed: true,
                  },
                ],
              }
            : null
        }
      />
    </Flex>
  )
}
