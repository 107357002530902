import qs from "qs"
import { fetchJson } from "js/includes/common/utils"

export function getPreEmptivePatches() {
  return fetchJson("/divisionconfig/windows_patch_management")
}

export function savePreEmptivePatch(values) {
  return fetchJson("/divisionconfig/windows_patch_management", {
    options: {
      method: "PUT",
      body: JSON.stringify(values),
    },
  })
}

export const fetchOsPatchesOverview = ({
  organizationId,
  osTypes,
  deviceTypes,
  patchTypeOptions,
  deviceState,
  daysSinceLastActive,
  signal,
}) => {
  const queryString = qs.stringify(
    {
      osTypes,
      deviceTypes,
      patchTypeOptions,
      ...(deviceState && { deviceState }),
      ...(daysSinceLastActive && {
        daysSinceLastActive,
      }),
    },
    { indices: false },
  )
  const url = `/patching/dashboard${organizationId ? `/${organizationId}` : ""}?${queryString}`
  return fetchJson(url, { options: { signal } })
}
