import styled from "@emotion/styled"
import PropTypes from "prop-types"
import DataListItem, { dataListItemPropTypes } from "./DataListItem"

const StyledList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

function DataList({ data = [], firstItemTopBorder = false, lastItemBottomBorder = true }) {
  return (
    <StyledList>
      {data.map((item, index) => (
        <DataListItem
          {...{
            ...item,
            key: item.id || item.labelText,
            withTopBorder: index === 0 && firstItemTopBorder,
            withBottomBorder: index !== data.length - 1 || lastItemBottomBorder,
          }}
        />
      ))}
    </StyledList>
  )
}

DataList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(dataListItemPropTypes)),
  firstItemTopBorder: PropTypes.bool,
  lastItemBottomBorder: PropTypes.bool,
}

export default DataList
